import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@stores/index';
import Article from '@pages/Article';
import { ArticleWrapper } from '@rambler-help/components';

const Legal = observer((): ReactElement => {
  const { articles } = useRootStore();
  return (
    typeof articles.article?.id === 'undefined' ? (
      <ArticleWrapper>
        <h2>
          Правила пользования и конфиденциальность
        </h2>
        <h4>
          <a href="/legal/1776/">Нормы использования</a>
        </h4>
        <p>
          Запреты и ограничения, которые необходимо соблюдать Пользователям на Ресурсах.
        </p>
        <h4>
          <a href="/legal/1142/">Политика конфиденциальности</a>
        </h4>
        <p>
          Политика в отношении обработки Данных Пользователей при использовании ими Ресурсов.
        </p>
        <h4>
          <a href="/legal/1145/">Регламент по рассмотрению жалоб и блокированию незаконного контента</a>
        </h4>
        <p>
          Условия рассмотрения жалоб на незаконный контент, размещенный на Ресурсах.
        </p>
        <h4>
          <a href="/legal/1430/">Условия использования</a>
        </h4>
        <p>
          Общие условия предоставления Ресурсов Пользователям.
        </p>
        <h4>
          <a href="/legal/1706/">Условия рассылок</a>
        </h4>
        <p>
          Условия рассылки Сообщений Пользователям.
        </p>
        <h4>
          <a href="/legal/2183/">Правила применения рекомендательных технологий</a>
        </h4>
        <p>
          Условия и правила применения Рекомендательных технологий на Ресурсах.
        </p>
        <p>
          Пользовательские документы отдельных Ресурсов:{' '}
          <a href="https://help.rambler.ru/legal/mail/">Рамблер/почта</a>.
        </p>
      </ArticleWrapper>
    ) : (
      <Article />
    )
  );
});

export default Legal;
